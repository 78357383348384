
import { instance } from '@/utils/http.js'

// 获取学生科目班级列表
export const getUserSubjectClass = function (params) {
  return instance({
    url: '/api/v1/user/user_class_list',
    method: 'get',
    params
  })
}

//用户各科目切换班级
export const saveSubjectClass = function (data) {
  return instance({
    url: '/api/v1/user/edit_use_subject',
    method: 'post',
    data
  })
}


// 获取用户可切换班级
export const getClassList = function (params) {
  return instance({
    url: '/api/v1/user/class_list',
    method: 'get', params
  })
}

// 一键切换班级
export const checkUserClass = function (data) {
  return instance({
    url: '/api/v1/user/one_change_class',
    method: 'post',
    data
  })
}