<template>
  <div class="subject_class_wrap">
    <el-form :model="form"
             label-width="120rem">
      <el-row :gutter="10">
        <el-col :span="23"
                v-for="(item,index) in list"
                :key="index">
          <el-form-item :label="item.subject_name">
            <el-select style="width:26vw"
                       v-model="item.id"
                       placeholder="请选择">
              <el-option v-for="item in item.class_list"
                         :key="item.class_id"
                         :label="item.class_name"
                         :value="item.class_id">
              </el-option>
            </el-select>
            <!-- <span class="remark">
              注：当前{{item.grade_name}};{{item.correct_name}}
            </span> -->
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="btn_wrap">
            <div class="btn"
                 @click="save">
              确 认
            </div>
            <div class="btn"
                 @click="quickSet">
              一键设置
            </div>
          </div>
        </el-col>
      </el-row>
      <QuickSet ref="QuickSetRef"
                @back="back"
                @initList="initList" />
    </el-form>
  </div>
</template>

<script>
import { getUserSubjectClass, saveSubjectClass } from '@/api/subject_class.js'
import { hasClass } from '@/api/class.js'
import { getUserInfo, editUserInfo } from '@/api/userInfo.js'

import QuickSet from './quickSet.vue'
export default {
  data () {
    return {
      form: {},
      list: []
    }
  },
  components: {
    QuickSet
  },
  mounted () {
    this.initList()
  },
  methods: {
    async initList () {
      let form = JSON.parse(window.sessionStorage.getItem('user_form'))
      let params = {
        grade_id: form.edu_grade_id
      }
      const { data } = await hasClass(params)
      this.list = data.list.map(item => {
        item.id = item.class_list[0].class_id
        return item
      })
    },
    async save () {
      let form = {
        subject_data: this.getForm(),
        grade_id: JSON.parse(window.sessionStorage.getItem('user_form')).edu_grade_id
      }
      await saveSubjectClass(form)
      this.$message.success('修改成功')
      this.back()
    },
    getForm () {
      let class_data = {}
      this.list.forEach(item => {
        class_data[item.subject_id] = item.id
      });
      return class_data
    },
    quickSet () {
      let form = {
        class_id: ''
      }
      this.$refs.QuickSetRef.form = form
      this.$refs.QuickSetRef.getList()
      this.$refs.QuickSetRef.dialogVisible = true
    },
    async back () {
      let form = JSON.parse(window.sessionStorage.getItem('user_form'))
      await editUserInfo(form)
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      userInfo.edu_grade_id = form.edu_grade_id
      window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.subject_class_wrap {
  margin-left: 6.88vw;
  width: 100%;
  height: 100%;
  margin-top: -40rem;
  span {
    text-indent: 20rem;
    display: inline-block;
  }
  ::v-deep .el-form-item__label {
    font-size: 24rem;
    font-weight: bold;
    color: #333333;
    line-height: 60rem;
  }
  ::v-deep .el-input--medium .el-input__inner {
    height: 60rem;
    line-height: 60rem;
    font-size: 24rem;
    font-weight: 500;
    color: #999999;
  }
}
.remark {
  font-size: 20rem;
  font-weight: 500;
  color: #666666;
}
.btn_wrap {
  display: flex;
  .btn {
    width: 21.35vw;
    height: 60rem;
    background: #2196f3;
    border-radius: 10rem;
    font-size: 24rem;
    font-weight: bold;
    color: #ffffff;
    line-height: 60rem;
    text-align: center;
    margin-bottom: 30rem;
    &:nth-child(2) {
      margin-left: 36rem;
    }
  }
}
</style>