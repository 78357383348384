<template>
  <div>
    <el-dialog title="一键设置"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="700rem"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="80rem">
        <el-row :gutter="10">
          <el-col :span="22">
            <el-form-item label="班级">
              <el-select style="width:350rem"
                         v-model="form.class_id"
                         placeholder="请选择">
                <el-option v-for="item in classList"
                           :key="item.id"
                           :label="item.class_name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="btn_warp">
        <div @click="handleClose">
          取消
        </div>
        <div @click="save">
          确定
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getClassList, checkUserClass } from '@/api/subject_class.js'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      classList: [],
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    async getList () {
      let params = {
        grade_id: JSON.parse(window.sessionStorage.getItem('user_form')).edu_grade_id
      }
      const { data } = await getClassList(params)
      this.classList = data.list
    },
    async save () {
      if (!this.form.class_id) {
        this.$message.warning('请选择班级')
        return
      }
      let form = JSON.parse(JSON.stringify(this.form))
      checkUserClass(form).then(res => {
        this.$message.success(res.msg)
        this.handleClose()
        this.$emit('initList')
        this.$emit('back')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23rem;
  font-size: 16rem;
  padding: 27rem;
  padding-top: 0;
}
.btn_warp {
  height: 68rem;
  border-top: 1rem solid #e5e5e5;
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  font-weight: bold;
  font-size: 24rem;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &:nth-child(1) {
      font-weight: bold;
      color: #999999;
      border-right: 1rem solid #e5e5e5;
    }
    &:nth-child(2) {
      font-weight: bold;
      color: #2196f3;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-dialog__header {
  font-size: 18rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
  padding-top: 28rem;
  padding-bottom: 0;
}
::v-deep .el-dialog__title {
  color: #333333;
  font-weight: 500;
  font-size: 24rem;
  line-height: 33rem;
}
::v-deep .el-dialog {
  border-radius: 20rem;
  box-shadow: 0rem 0rem 12rem 0rem rgba(184, 184, 184, 0.72);
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 320rem;
  position: relative;
}

::v-deep .el-form-item__label {
  font-weight: 500;
  color: #333333;
  font-size: 24rem;
  line-height: 60rem;
}
::v-deep .el-input--medium .el-input__inner {
  height: 60rem;
  line-height: 60rem;
  font-weight: 500;
  color: #333333;
  font-size: 24rem;
}
.el-row {
  margin-top: 60rem;
  margin-left: 137rem !important;
}
</style>